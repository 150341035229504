<template>
  <div id="app">

    <Header />
    <router-view :key="$route.fullPath" /> 


  </div>
</template>

<script>

import './assets/css/bootstrap.min.css';
import './assets/css/style.css';

import Header from "@/components/Header.vue";

export default {
  name: "App",
  data () {
    return {
      
    }
  },
  components : {
    Header
  }
}
</script>
