import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Signature from '@/views/Signature.vue'

const routes = [
  {
    path: '/',
    name: 'signature',
    component: Signature
  },
  {
    path: "/id",
    name: "id"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass : 'active',
  routes
})

export default router