<template>

  <section  class="container">
      <div class="row my-5">

        <div class="col-12 mb-2">
          <h1>Generate your Adhyayan Signature</h1>
          <p class="lead">Simply add name and your work details and generate an email signature.</p>
        </div>

        <div class="col-md-4 py-4">

          <label class="form-group d-block">
              <span>Your Name</span>
              <input type="text" placeholder="eg. John Smith" class="form-control" required v-model="formdata.name" />
          </label>

          <label class="form-group d-block">
              <span>Your Designation (optional)</span>
              <input type="text" placeholder="eg. Chief Executive Officer" required class="form-control" v-model="formdata.designation" />
          </label>


          <label class="form-group d-block">
              <span>Your Mobile Number (optional)</span>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">+977-</span>
                </div>
                <input type="text" placeholder="eg. 9841000000" class="form-control" required v-model="formdata.mobile" />
              </div>
          </label>

          <label class="form-group d-block">
              <span>Your Email Address</span>
              <div class="input-group">
                <input type="email" placeholder="eg. johnsmith" class="form-control" required v-model="formdata.email" />
                <div class="input-group-append">
                  <span class="input-group-text">@adhyayan.edu.np</span>
                </div>
              </div>
          </label>




          <label class="form-group d-block">
              <span>Your Skype ID (optional)</span>
              <input type="text" placeholder="eg. johnsmith" class="form-control" v-model="formdata.skype" />
          </label>


        </div>

        <div class="col-md-8">
            <section class="bg-white border shadow p-4 m-4 animated preview_pane" :class="{ 'border-danger' : copied }">
              <Preview :formdata="formdata" :copied="copied" id="preview" ref="preview" />
            </section>

          <div class="p-4">
            <button type="button" @click="copyToClipboard()" class="btn btn-danger mr-3" :disabled="!formdata.name || !formdata.email">Copy to Clipboard</button>

            <span class="text-success" v-if="copied">✅ Copied to clipboard</span>
            <p class="mt-4 text-muted small">Once copied, go and paste the content in your email signature.</p>
          </div>
          
        </div>

      </div>
  </section>
</template>



<script>


import Preview from "@/components/SignaturePreview.vue";

export default {
  name: "App",
  data () {
    return {
      copied: false,
      formdata : {
        name : null,
        designation: null,
        email: null,
        mobile: null,
        skype: null
      }
    }
  },
  methods : {
    selectText(element) {
      var range;
      if (document.selection) {
          range = document.body.createTextRange();
          range.moveToElementText(element);
          range.select();
        } else if (window.getSelection) {
          range = document.createRange();
          range.selectNode(element);
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
        }
    },
    copyToClipboard() {
        const element =  document.querySelector('.preview_pane');
        element.classList.remove('pulse')

        this.copied = false;
        this.selectText(document.getElementById('preview'));
        document.execCommand("copy");

        element.classList.add('pulse')
        this.copied = true;
    }
  },
  components : {
    Preview
  }
}
</script>
