<template>
  <header class="bg-light shadow">
    <nav class="nav p-2">
        <li class="nav-item mr-4">
            <img src="https://adhyayan.edu.np/wp-content/uploads/2020/04/logo.png" height="36" >
        </li>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header"
};
</script>
