<template>
    <main>
      <table style="max-width:600px;line-height:1.2;border:0">
      <tr>
        <td colspan="2" style="padding-bottom: 8px;font-family: Arial, sans-serif">Best regards,</td>
      </tr>
      <tr>
        <td valign="top" width="220"><img src="https://adhyayan.edu.np/wp-content/uploads/2020/04/logo.png" width="180" style="margin: 8px;" /></td>
        <td style="border-left: 1px solid #DDD; padding-left: 16px; font-family: Arial, sans-serif;">
          <div style="margin-bottom: 8px;">
            <p style="color: #14254a; margin: 0; font-weight:bold; text-transform: capitalize" v-if="formdata.name">{{ formdata.name }}</p>
            <p class="text-muted m-0" v-if="!formdata.name">Your Name</p>
            <small style="color: gray" v-if="formdata.designation">{{ formdata.designation }}</small>
          </div>
          <div style="font-size: 90%; color:#14254a;">
            <small>Samakhusi Chowk, Tokha-10, Kathmandu 44600 Nepal<br /></small>
            <small>tel: +977-1-4383883 <span v-if="formdata.mobile">, +977-{{ formdata.mobile }}</span> <br></small>
            <small v-if="formdata.email">email: {{ formdata.email }}@adhyayan.edu.np<br></small>
            <small>web: www.adhyayan.edu.np</small><br>
            <small v-if="formdata.skype">skype: {{ formdata.skype }}</small>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="font-family: Arial, sans-serif; color: gray; font-size: 80%; padding-top: 18px">
          <small>The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.</small>
        </td>
      </tr>
    </table>
    </main>
</template>

<script>
export default {
    name: "Preview",
    props: ["formdata", "copied"]
}
</script>